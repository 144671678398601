/*

Customize variables:

- Check the ../../arDominionB5Plugin/scss/variables.scss file.
- Check the B5 documentation (https://getbootstrap.com/docs/5.0/customize/sass/).
- Import B5 functions, variables and mixins if needed.

*/
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Merriweather+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import "_variables.scss";
@import "../../arDominionB5Plugin/scss/main.scss";
@import "_custom.scss";

/*

Extend:

- Add extra SCSS and CSS imports.
- Add custom styles directly.

*/
