$black: #000;
$white: #fff;

// Cave Canem Custom
$off-white: #fffef1;
$light-gray: #495057;
$dark-gray: #333;
$hffamily: 'Merriweather Sans', sans-serif;
$pffamily: 'Lora', serif;
$olive: #5e7f4f;
$light-green: #87ad78;
$beige: #eee7deff;

// BS5 Override
$gray-400: #ced4da;
$border-color: $gray-400;
$primary: $black;
$secondary: $dark-gray;
//$white: $off-white;
$body-bg: $black;
//$body-color: $off-white;
$link-color: $primary;
//$navbar-light-icon-color: $dark-gray;
$navbar-light-color: $dark-gray;
$navbar-light-hover-color: $primary;
$headings-font-family: $hffamily;
$input-btn-font-family: $hffamily;
$font-family-base: $pffamily;
// $breadcrumb-bg: $white;
$nav-tabs-link-active-bg: $primary;
$nav-tabs-link-active-color: $white;
$nav-tabs-link-active-border-color: $primary $primary  $border-color; //null;
//$navbar-light-toggler-icon-bg: $white;
//$navbar-dark-brand-color: $off-white;