body {
    background-color: $beige;
}

// Header
header {
    min-height: 94px;
}

#site-title {
    color: $primary;
    font-family: $hffamily;
    font-size: 1.75rem;
    font-weight: 600;
    line-height: 1;
    margin-top: 60px;
    margin-left: 155px;
}

#navbar-toggler {
    color: $white;
    margin-top: 48px;
}

#site-description {
    font-family: $hffamily;
    margin-left: 167px;
}

#site-logo {
    max-height: -webkit-fill-available;
}

@media (max-width: 386px) {
    header {
        min-height: 70px;
    }

    #site-logo {
        height: 120px;
    }

    #site-title {
        font-size: 1.5rem;
        margin-top: 40px;
        margin-left: 132px;
    }

    #navbar-toggler {
    margin-top: 22px;
}

    #site-description {
        font-size: .75rem;
        margin-left: 142px;
    }
}

// Footer
footer {
    color: $white;
    padding-top: 1rem;
    background-color: $olive;
}


// Lists
.dropdown-header {
    color: $primary;
}

.dropdown-item, .list-group-item-action {
    &.active {
        color: $primary;
    }

    &:hover {
        color: $white;
        background-color: $olive;
    }
}

// Body
#main-column {
    margin-top: 2rem;

    h1, .multiline-header {
        color: $black;
    }
}

#sidebar {
    margin-top: 2rem;
}

#wrapper {
    padding-bottom: 2.5rem;
}

#wrapper > .alert-warning {
    margin-top: 2.5rem;
}

.accordion-header .accordion-button {
    color: $primary;
}

#context-menu nav {
    background-color: $white;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 1rem;
}

// Breadcrumb --> white background
.breadcrumb-item.active {
    background-color: $white;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-top-left-radius: 0rem;
    border-bottom-left-radius: 0rem;
    padding: 0.25rem;
}

.breadcrumb-item {
    background-color: $white;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    padding: 0.25rem;
}

.nav-item .nav-link {
    background-color: $white;
}

#sidebar .repository-logo-text h2 {
    border-radius: 0.25rem;
}

// Thumbnail Image --> white background
.img-thumbnail {
    background-color: $white;
    border-radius: 0rem;
}